// @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
// // $baseFont:"Montserrat", sans-serif !important;
// $baseFont:'Open Sans', sans-serif !important;
@import "../../api/assets/theme/_mixins.scss";
$baseColor: #f2cb77;
$whiteColor: #ffffff;
$colorBlack: #000000;
$colorBlack1: #666666;
$yellowColor: #e37b00;
$greenColor: #00be00;
$sidebarBg: #ececec;
$white: #fff;
$blackText: black;
$baseText: #fcd535;
$baseBg: #fcd535;
$border-hover: #fcd535;
$input-border: #eaecef;
$whiteBg: #fff;
$primary: #050823;
$secondary: #131841;
$inputBg: #0f1334;
$lightBlue: #3486ff;
$accent: #fd6f53;
$textWhite: #ffffff;
$font-color: #000000;
$buttonBlue: #3486ff;
$textPurple: #6d79b4;
$colorBlack2: #b9b8bb;
$textPurpleLight: #6e739a;
$whiteColor: #ffffff;
$colorBlack: #000000;
$colorBlack1: #666666;
$yellowColor: #e37b00;
$greenColor: #00be00;
$bordercolor: #34384c;
$graycolor: #9e9e9e;
$sidebarBg: #ececec;

$themes: (
  light: ("tabbg": #f3f3f3,
    "card": white,
    "ExchangeBg": whitesmoke,
    "Text-theme-achor": black,
    "Thead-Bg": whitesmoke,
    "table-border-exchnage": #eaecef,
    "test": red,

    "table-hover-exchange": #fafafa,
    "rail-range-bg": rgba(0, 0, 0, 0.04),
    "HeadercolorText": #ffffff,
    "HeaderButtonBlue": #3486ff,
    "HeaderHoverColorText": #3486ff,
    "HeaderBtnColorText": #fff,
    "footerTextColor": #fff,
    "footerBackgroundColor": #050823,
    //internal pages
    "themeprimary": #050823,
    "themeSecondary": #131841,
    "themetextWhite": #ffffff,
    "themetextWhite2": #ffffff,
    "themetextPurpleLight": #6e739a,
    "sidebarBg": #050823,
    "sidebariconBg": #071c37,
    "primaryBg": #070b2f,
    "primaryBg2": #070b2f,
    "secondaryBg": #050823,
    "sidebariconBgMob": #050823ad,
    "borderColor": #081030,
    "borderCardColor": #081030,
    "borderColor2": #131841,
    "borderColor3": #070b2f,
    "titletextColor": #f0f0f0,
    "textColorblue": #3486ff,
    "buttonOutline": #050823,
  ),
  dark: ("tabbg": #2a2d35,
    "card": #1e2026,
    "ExchangeBg": #12131b,
    "Text-theme-achor": white,
    "Thead-Bg": #272931,
    "table-border-exchnage": #34384c,
    "table-hover-exchange": #272931,
    "rail-range-bg": rgba(0, 0, 0, 0.04),
    "test": green,
    "HeadercolorText": #3486ff,
    "HeaderHoverColorText": #fff,
    "HeaderBtnColorText": #fff,
    "footerTextColor": #000,
    "footerBackgroundColor": #f1f1f1,
    //internal pages
    "themeprimary": #f1f1f1,
    "themeSecondary": #f1f1f1,
    "themetextWhite": #333333,
    "themetextWhite2": #999999,
    "themetextPurpleLight": #999999,
    "sidebarBg": #ececec,
    "sidebariconBg": #3486ff,
    "sidebariconBgMob": #3486ff,
    "primaryBg": #ffffff,
    "primaryBg2": #f1f1f1,
    "secondaryBg": #ffffff,
    "borderColor": #f1f1f1,
    "borderCardColor": #e4e4e4,
    "borderColor2": #e2e2e2,
    "borderColor3": #e2e2e2,
    "titletextColor": #333333,
    "textColorblue": #333333,
    "buttonOutline": #333333,
  ),
);

%bg-shadow {
  background-color: $whiteBg;
  box-shadow: 1px 1px 12px 10px rgba(0, 0, 0, 0.02);
  border-radius: 8px;
}

%main-text {
  font-weight: 500;
  @include fluid-type(320px, 767px, 14px, 26px);
  color: #333;
}

.headtext {
  @include fluid-type(320px, 767px, 15px, 20px);
  font-weight: 500;
  color: #333;
}