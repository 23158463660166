@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,700&display=swap");

@import "./assets/theme/_var.scss";
@import "./assets/theme/_mixins.scss";
// @import "~antd/lib/style/index";

html {
  font-size: 62.5%;

  @media (max-width: 767px) {
    font-size: 56.5%;
  }
}

* {
  margin: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

input[type="radio"] .disableCaret {
  -webkit-user-select: none;
  /* Chrome all / Safari all */
  -moz-user-select: none;
  /* Firefox all */
  -ms-user-select: none;
  /* IE 10+ */
  user-select: none;
}

.custom-radio {
  display: flex;

  .ant-radio-wrapper .ant-radio-checked {
    .ant-radio-inner {
      border-color: $baseBg;
      background-color: $baseBg;
    }
  }

  .ant-radio-wrapper {
    &:hover {
      .ant-radio-inner {
        border-color: $baseBg;
      }
    }
  }
}

.container-custom {
  margin: 0 auto;
  width: 100%;
  padding: 0 35px;
  // padding: 0 44px;
  // border: 1px solid red;

  @media (min-width: 1600px) {
    max-width: 1410px;
    // padding: 0 44px;
  }

  @media (max-width: 1399px) {
    max-width: 1320px;
    padding-right: 30px;
    padding-left: 30px;
    padding: 0 44px;
  }

  @media (max-width: 1199px) {
    max-width: 1140px;
    padding: 0 20px;
  }

  @media (max-width: 991px) {
    max-width: 960px;
  }

  @media (max-width: 767px) {
    max-width: 720px;
  }

  @media (max-width: 575px) {
    max-width: 540px;
    padding: 0 15px;
  }
}

h2.main-text {
  @extend %main-text;
}

.footerContact {
  padding: 20px 44px;
  background-color: #fff;
}

.ant-form-item .ant-form-item-label>label::after {
  content: none;
}

.lable-input {
  .ant-form-item {
    .ant-form-item-row {
      flex-direction: column;
    }

    .ant-form-item-label {
      text-align: left;
    }
  }
}

.spacing-5 {
  margin-top: 20px;
}

.spacing-3 {
  margin-top: 15px;
}

.spacing-2 {
  margin-top: 10px;
}

// -------------card-outer---------------------/
.card-outer {
  max-width: 545px;
  width: 100%;
  margin: 0 auto;
  @extend %bg-shadow;
  padding: 50px 40px;

  @media (max-width: 575px) {
    padding: 30px;
  }
}

.card-outer-basic {
  @extend %bg-shadow;

  // @include theme() {
  //   background-color: theme-get("card")
  // }
}

// ==========================================================================

// -----------------------comman-header--------------------
.logo {
  max-width: 225px;
}

.Comman-header {
  padding: 20px 44px;
  background-color: $whiteBg;

  @media (max-width: 575px) {
    padding: 24px 26px;
  }

  img {
    cursor: pointer;
  }
}

.profile-page {
  .form-button {
    button.ant-btn {
      max-width: 600px;
    }
  }
}

hr {
  color: #eaecef;
  margin: 25px 0 50px 0;
  opacity: 0.2;

  @media (max-width: 575px) {
    margin: 25px 0 25px 0;
  }
}

// -------------------------inner-content---------------
.inner-content {
  min-height: calc(100vh - 160px);
  padding: 60px 0;

  @media (max-width: 575px) {
    padding: 20px 0;
  }

  &.card-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.transhis {
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      padding: 23px 0 !important;
    }

    .react-datepicker__view-calendar-icon {
      padding-left: 16px !important;
    }
  }
}

.userFormHeader {
  text-align: center;

  img {
    @media (max-width: 575px) {
      max-width: 60px;
    }
  }

  h1 {
    margin: 0;
    font-size: 2.6rem;
    font-weight: 500;

    span {
      background-color: #fff;
      padding: 15px 7px;
      display: inline-table;
      color: #132559;
      font-size: 15px;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      left: 50%;
    }
  }

  p {
    color: #333;
    font-size: 13px;
    font-weight: 400;
    padding-top: 0;
    margin-top: 0;
    // white-space: nowrap;
  }

  .iconForLogin {
    max-width: 300px;
    display: flex;
    margin: 15px auto 0 auto;
    align-items: center;
    // margin: 0px auto;
    border: solid 1px #ccc;
    padding: 4px 10px;
    box-shadow: 0px 0px 3px #ddd inset;
    border-radius: 6px;

    a {
      padding-left: 8px;
      font-size: 10px;

      color: #333;
    }

    svg {
      color: #008141;
      font-size: 10px;
      padding: 5px;
      opacity: 1 !important;
      border-right: solid 1px #ccc;
    }
  }
}

// ----------------------------------footerContact-------------
// footer.footerContact {
//   padding: 20px 44px;
//   background-color: $whiteBg;
// }

.loginForm {
  .ant-tabs {
    margin-bottom: 0;

    .ant-tabs-tab-btn:active {
      color: $blackText;
    }

    .ant-tabs-nav {
      .ant-tabs-tab {
        &-active {
          .ant-tabs-tab-btn {
            color: $blackText;
            font-weight: 600;
          }
        }

        &:hover {
          color: black;
        }
      }
    }

    .ant-tabs-ink-bar {
      background-color: $baseBg;
    }
  }
}

iframe {
  @media (max-width: 390px) {
    width: 100% !important;
  }
}

.ReigisterForm {
  .ant-form-item {
    margin-bottom: 20px;
  }

  .form-button {
    button {
      margin-top: unset;
    }
  }

  .ant-select-selection-placeholder {
    font-weight: 500;
    font-size: 14px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
      "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
      "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  }

  .ant-checkbox-wrapper.ant-checkbox-wrapper-in-form-item {
    position: relative;

    &:last-child {
      padding-left: 20px;
    }

    .ant-checkbox.ant-wave-target {
      position: absolute;
      top: 3px;
      left: 1px;
    }
  }
}

.userFormHeader {
  .register_innerHeading {
    margin: 35px 0px;
  }
}

.loginRegister-link {
  margin-top: 20px;
  text-align: center;

  .forgotpassword-link {
    color: $baseText;

    &:hover {
      text-decoration: underline;
    }
  }
}

.form-button,
.otpSpan {
  text-align: center;

  button {
    width: 100%;
    font-size: 14px;
    font-weight: 600;
    height: 50px;
    // margin: 50px 0 26px 0;

    span {
      text-transform: uppercase;
      font-weight: 500;
    }
  }

  p {
    font-weight: 400;
    font-size: 16px;
    color: #333;
    // text-align: right;
    // text-transform: uppercase;

    a {
      color: #fcd535;
      border-bottom: 1px solid transparent;
      transition: all 0.25s;

      &:hover {
        // border-bottom: 1px solid #fcd535;
      }
    }
  }
}

.searchbtn {
  margin-bottom: 5px;

  @media (max-width: 991px) {
    margin-top: 20px;
  }

  button {
    height: 48px;
  }
}

.searchreset {
  display: flex;
  gap: 20px;

  @media (max-width: 991px) {
    margin-top: 20px;
  }
}

// .anticon-eye {
//   color: black;
//   /* Replace with the color you want for the invisible state */
// }

// ---------------------------custom-input---------------
.ant-input-affix-wrapper-focused {
  span.ant-input-prefix {
    svg {
      color: black;
    }
  }
}

.ant-form-item-control-input {

  .ant-input-search .ant-input-group .ant-input-affix-wrapper,
  .ant-input-affix-wrapper {
    border-color: $input-border;
    border-radius: 8px;
    padding: 12px;

    &:hover {
      border-color: $border-hover;
    }

    &:focus,
    &:focus-within,
    &-focused {
      box-shadow: unset;

      span.ant-input-prefix {
        svg {
          color: black;

          path {
            color: black;
          }
        }
      }
    }

    .ant-input-prefix {
      svg {
        color: #a5a5ab;
        margin-right: 5px;
      }
    }
  }
}

.form-captcha {
  text-align: center;

  @media (max-width: 575px) {
    iframe {
      transform: scale(0.8);
    }
  }
}

// ----------------------commanbtn------------------------
button.ant-btn {
  background-color: $baseBg;
  border: 1px solid $baseBg;
  font-size: 1.3rem;

  &:hover {
    background-color: transparent;
    color: $baseBg;
  }

  &:disabled,
  .ant-btn-disabled {
    opacity: 0.8;
  }

  &.ant-btn-default:not(:disabled):not(.ant-btn-disabled):active {
    color: black;
    border-color: $baseBg;
  }

  &.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    color: $baseBg;
    border-color: $baseBg;
  }

  &.buy-btn {
    background-color: #0ecb81;
    border-color: #0ecb81;
    color: #fff;

    &:hover,
    &:focus {
      background-color: transparent;
      color: #0ecb81;
    }
  }

  &.sell-btn {
    background-color: #f6465d;
    border-color: #f6465d;

    &:hover,
    &:focus {
      background-color: transparent;
      color: #f6465d;
    }
  }
}

.tabs-with-button {
  button.ant-btn {
    background-color: transparent;
    border: 1px solid transparent;
    font-size: 1.4rem;

    &.active {
      background-color: $baseBg;
    }

    &:hover,
    &:focus {
      // background-color: $baseBg;
      color: black;
    }

    &:disabled,
    .ant-btn-disabled {
      opacity: 0.8;
    }

    &.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
      color: black;
      border-color: transparent;
    }

    &.buy-btn {
      background-color: #0ecb81;
      border-color: #0ecb81;

      &:hover,
      &:focus {
        background-color: transparent;
        color: #0ecb81;
      }
    }

    &.sell-btn {
      background-color: #f6465d;
      border-color: #f6465d;

      &:hover,
      &:focus {
        background-color: transparent;
        color: #f6465d;
      }
    }
  }
}

// .ReigisterForm {
.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
  border-color: $border-hover;
  box-shadow: unset;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #eaecef;
}

.ant-select-focused .ant-select-selector,
.ant-select-selector:focus,
.ant-select-selector:active,
.ant-select-open .ant-select-selector {
  border-color: $border-hover  !important;
  box-shadow: none !important;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  padding: 23px 11px;

  // .ant-select-selection-search {
  //   input {
  //     display: none !important;
  //   }
  // }
}

.ant-select {
  margin-bottom: 20px;

  .ant-select-selector {
    padding: 23px 11px;
  }

  .ant-select-arrow {
    top: 71%;
  }
}

// ------------------------checkbox------------------------
.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover .ant-checkbox-checked:not(.ant-checkbox-disabled) .ant-checkbox-inner {
  background-color: $baseBg;
  border-color: $baseBg;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: $baseBg;
  border-color: $baseBg;
}

// ----------------------modal-----------------

.ant-modal-root {
  .ant-modal-mask {
    backdrop-filter: blur(4px);
  }

  .ant-modal {
    .ant-modal-content {
      padding: 50px 30px;

      @media (max-width: 575px) {
        padding: 30px;
      }

      .ant-modal-body {
        .modelContent {
          img {
            max-width: 80px;
            width: 100%;
            height: 80px;
            margin: 0 auto 10px auto;
            display: block;
          }

          label {
            align-items: baseline !important;
          }
        }
      }
    }
  }
}

.get_otp {
  .ant-input-affix-wrapper.ant-input-compact-item.ant-input-compact-first-item.css-dev-only-do-not-override-3mqfnx {
    border-right: 0 !important;

    &:hover {
      border: 1px solid #eaecef !important;
      border-right: 0 !important;
    }
  }

  button {
    background-color: transparent;
    border: 1px solid #eaecef;
    border-left: 0;

    &:hover {
      border: 1px solid #eaecef !important;
      border-left: 0 !important;
    }
  }

  .ant-input-affix-wrapper-status-error {
    border: 1px solid #eaecef !important;
    border-right: 0 !important;
  }
}

// -----------------------------------calender-----------
.laftpadding-0 {
  .react-datepicker-wrapper {
    background-color: rgb(245, 245, 245);
  }

  .custom-datepicker-ant.react-datepicker__view-calendar-icon {
    padding-left: 20px !important;
  }
}

.react-datepicker-wrapper {
  width: 100%;

  &:focus-visible {
    border-color: $baseText;
  }

  input.ant-input {
    height: 48px;
    background-color: rgb(245, 245, 245);
    // background: rgb(245, 245, 245) url("") no-repeat right;
    background-size: 20px;
  }

  .ant-input[disabled] {
    border-color: #eaecef;
  }
}

.Buy-Sell {
  .customTab_style {
    padding: 15px 0;
  }

  .ant-tabs-tab {
    padding-bottom: 30px !important;
  }

  .ant-tabs-content {
    padding: 0 20px !important;
  }

  .height100 {
    min-height: 100%;
  }
}

.ant-slider .ant-slider-handle:active::after,
:where(.css-dev-only-do-not-override-3mqfnx).ant-slider .ant-slider-handle:focus::after {
  box-shadow: 0 0 0 2px #fcd535;
}

.ant-slider .ant-slider-handle:hover::after,
.ant-slider .ant-slider-handle:active::after,
:where(.css-dev-only-do-not-override-3mqfnx).ant-slider .ant-slider-handle:focus::after {
  box-shadow: 0 0 0 4px #fcd535;
}

.ant-slider {
  margin-bottom: 40px !important;

  .ant-slider-dot-active {
    border-color: #fcd535;
  }

  &:hover {
    .ant-slider-handle {
      &::after {
        box-shadow: 0 0 0 2px #fcd535;
      }
    }
  }

  .ant-slider-track {
    background-color: #fcd535;
  }

  &:hover {
    .ant-slider-track {
      background-color: #fcd535;
      background-color: #fcd535;
    }

    .ant-slider-dot-active {
      border-color: #fcd535;
    }
  }

  .ant-slider-handle {
    &::after {
      box-shadow: 0 0 0 2px #fcd535;
    }
  }
}

.paymentCheckbox {
  margin-right: 15px;

  input[type="radio"] {
    border: 2px solid white;
    box-shadow: 0 0 0 1px #fcd535;
    appearance: none;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    background-color: #fff;
    transition: all ease-in 0.2s;
    border-radius: 50% !important;

    &:hover {
      cursor: pointer;
    }
  }

  input[type="radio"] {
    user-select: none;

    &:checked {
      background-color: #fcd535;
      border-radius: 50%;
      user-select: none;
    }
  }

  img {
    height: 30px;
    border-radius: 2px;
  }
}

// ----------------------------depositWithDraw-------------------
.title-search-sec {
  padding: 16px 32px;
}

// .closeMarketDrawer {
//   .customTab_style.tabs-button .ant-tabs .ant-tabs-nav {
//     width: 75px;
//   }
// }

.customTab_style {
  padding: 25px 32px;

  &.moreTabs {
    padding: unset;

    .ant-tabs-nav {
      margin-bottom: unset;
    }

    .ant-tabs {
      .ant-tabs-nav-list {
        width: unset;

        .ant-tabs-tab {
          flex-basis: unset;
          padding: 30px 32px 15px 32px;
        }
      }
    }
  }

  &.exchangeTabs {
    padding: unset;

    .ant-tabs-tab+.ant-tabs-tab {
      margin: unset;
    }

    .ant-tabs-nav {
      margin-bottom: unset;
    }

    .ant-tabs {
      .ant-tabs-nav-list {
        width: 100%;

        .ant-tabs-tab {
          flex-basis: auto;
          padding: unset;

          .ant-tabs-tab-btn {
            padding: 10px;
          }
        }
      }
    }
  }

  &.tabs-button {
    .ant-tabs {
      .ant-tabs-nav {
        width: 50%;
      }

      .ant-tabs-tab+.ant-tabs-tab {
        margin-left: unset;
      }

      .ant-tabs-nav-list {
        width: unset;

        .ant-tabs-tab {
          flex-basis: unset;
          padding: 5px 10px;
        }
      }
    }
  }

  .ant-tabs {
    .ant-tabs-ink-bar {
      background: $baseBg;
    }

    .ant-tabs-nav {
      width: 100%;
    }

    .ant-tabs-ink-bar {
      height: 1px;
    }

    .ant-tabs-nav-list {
      width: 100%;

      .ant-tabs-tab {
        flex-basis: 50%;
        text-align: center;
        padding-bottom: 15px;

        .ant-tabs-tab-btn {
          width: 100%;
          color: $baseText;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
        }
      }
    }
  }
}

.ant-menu-horizontal {
  border: none;
}

.commonDepositWithdraw {

  .customTab_style,
  .table-Withdrawal-deposite {
    min-height: 100%;
  }

  .customTab_style {
    // padding: 25px 32px;
    padding: 0;

    .ant-tabs {
      .ant-tabs-ink-bar {
        background: $baseBg;
      }

      .ant-tabs-nav {
        width: 100%;
      }

      .ant-tabs-nav-list {
        width: 100%;
        padding: 13px 0;
        padding-bottom: 0;

        .ant-tabs-tab {
          flex-basis: 50%;
          text-align: center;
          padding-bottom: 15px;

          .ant-tabs-tab-btn {
            width: 100%;
            color: $baseText;
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
          }
        }
      }

      .ant-tabs-tabpane {
        padding: 10px 20px;
      }
    }
  }

  // .table-Withdrawal-deposite {
  //   .deposit-history {}
  // }
}

.link-design {
  color: $baseText;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: $baseText;
  }
}

.order_histry {
  .ant-table {
    table {
      td {
        white-space: nowrap !important;
      }
    }
  }
}

// ---------------------customdatepiccker-ant------------------

.settingPage {
  .react-datepicker__triangle {
    left: -21px !important;
  }

  .custom-datepicker-ant .react-datepicker-popper {
    width: 70% !important;

    @media (max-width: 991px) {
      width: 50% !important;
    }

    @media (max-width: 445px) {
      width: 100% !important;
    }
  }
}

.react-datepicker {
  width: 100%;
}

.react-datepicker__year-read-view--down-arrow {
  top: -4px;
}

.custom-datepicker-ant .react-datepicker__view-calendar-icon {
  padding-left: 0;
}

.custom-datepicker-ant {
  .react-datepicker__input-container.react-datepicker__view-calendar-icon {
    svg {
      path {
        fill: #a5a5ab !important;
      }
    }
  }

  .react-datepicker__month-container {
    float: unset;
  }

  .react-datepicker__calendar-icon {
    z-index: 1;
  }

  .react-datepicker-popper {
    width: 100%;
    z-index: 3 !important;

    @media (max-width: 767px) {
      width: 60%;
    }

    @media (max-width: 575px) {
      width: 75%;
    }

    .react-datepicker__day-name,
    .react-datepicker__year-read-view--selected-year,
    .react-datepicker__month-read-view--selected-month {
      font-size: 13px;
    }

    .react-datepicker__day-names {
      display: flex;
      justify-content: space-between;
    }

    .react-datepicker__week {
      font-size: 13px;
      display: flex;
      justify-content: space-between;
    }

    .react-datepicker__current-month,
    .react-datepicker__year-dropdown-container .react-datepicker__year-dropdown-container--scroll,
    .react-datepicker__month-option,
    .react-datepicker__year-option .react-datepicker__year-option--selected_year {
      font-size: 11px;
    }

    .react-datepicker__year-option,
    .react-datepicker__month-option,
    .react-datepicker__month-year-option {
      font-size: 11px;
    }

    .react-datepicker__navigation--years-upcoming {
      &::after {
        rotate: 180deg;
        left: 20% !important;
        top: 30% !important;
      }
    }

    .react-datepicker__navigation--years-upcoming,
    .react-datepicker__navigation--years-previous {
      position: relative;

      &::after {
        content: "";
        position: absolute;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 40%;
        background-image: url("./images/arrow-down.png");
        height: 12px;
        width: 12px;
        background-repeat: no-repeat;
        background-size: cover;
      }
    }
  }

  .react-datepicker__year-option:hover,
  .react-datepicker__month-option:hover,
  .react-datepicker__month-year-option:hover {
    background-color: #434040;
    color: #fff;
  }

  .react-datepicker-wrapper {
    border: 1px solid $input-border;
    border-radius: 6px;
    box-shadow: unset;

    &:focus,
    &:focus-visible {
      outline: unset;
    }

    &:hover {
      border: 1px solid #fcd535;
    }
  }

  .react-datepicker__view-calendar-icon {
    display: inline-flex;
    align-items: center;
    padding-left: 11px;
  }

  .react-datepicker-ignore-onclickoutside {
    width: 100%;
    border-radius: 6px;

    &:focus,
    &:focus-visible {
      border: 1px solid #fcd535;
      outline: unset;
    }
  }

  .react-datepicker-wrapper {
    overflow: hidden;

    input {
      width: 100%;
      height: 46px;
      border: unset;
      overflow: hidden;

      &:focus-visible {
        border-color: $baseText;
        border: none;
      }
    }
  }
}

// -----------------customswitch---------------
.custom-switch {
  .ant-switch:hover {
    background-color: grey;
  }

  .ant-switch.ant-switch-checked:hover:not(.ant-switch-disabled) {
    background-color: grey;
  }

  button[aria-checked="true"] {
    background-color: grey;

    .ant-switch-handle {
      background-image: url("./assets/images/sun.png");
      background-size: contain;

      &::before {
        display: none;
      }
    }
  }

  button[aria-checked="false"] {
    background-color: grey;

    .ant-switch-handle {
      background-image: url("./assets/images/moonWhite.png");
      background-size: contain;

      &::before {
        display: none;
      }
    }
  }
}

.full-width-header {
  ul.ant-menu-overflow.ant-menu.ant-menu-root.ant-menu-horizontal.ant-menu-light {
    display: inline-flex;
    align-items: center;
  }

  .ant-btn {
    height: 35px;
    font-size: 13px;
    font-weight: 500;
  }
}

// ---------------------------------custom-select--------------
%select-icon-text {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  text-transform: uppercase;
}

#abc {
  .ant-select-item-option-content {
    @extend %select-icon-text;

    img {
      margin-right: 5px;
    }
  }
}

.ant-form-item {
  @media (max-width: 991px) {
    margin-bottom: 6px;
  }
}

.customSelect {
  span.ant-select-selection-item {
    @extend %select-icon-text;

    img {
      margin-right: 5px;
    }
  }

  // .ant-select {
  //   &-open {
  //     .ant-select-item-option-content {
  //       display: inline-flex !;
  //       align-items: center;
  //       text-transform: uppercase;

  //       img {
  //         margin-right: 5px;
  //       }
  //     }

  //   }
  // }
}

// -----------------------------custom-search----------------
.custom-search {
  .ant-input-search :where(.css-dev-only-do-not-override-3mqfnx).ant-input-group .ant-input-affix-wrapper {
    border-radius: 8px;
  }

  span.ant-input-group-addon {
    display: none;
  }
}

// --------------------ant-custom-table------------------
.custom-table {
  overflow-x: auto;

  .ant-skeleton {
    padding: 0px 20px;
  }

  * {
    button.ant-btn {
      font-size: 1.2rem;
    }
  }

  &.exchange-table {
    .ant-table-wrapper {
      .ant-table {
        table {

          th,
          td {
            padding: 5px 12px;
            font-size: 12px;
            width: 33.3%;

            &:nth-child(2) {
              text-align: center;
            }

            &:nth-child(3) {
              text-align: right;
            }
          }

          // thead.ant-table-thead {
          //   position: sticky;
          //   top: 0;
          // }
        }
      }
    }
  }

  &.history-table {
    @media (max-width: 991px) {
      overflow-x: scroll;
    }

    table {
      table-layout: auto !important;
    }

    .ant-table-wrapper {
      .ant-table {
        table {
          @media (max-width: 991px) {
            min-width: 1200px;
          }

          td {
            padding: 2px 0px;
          }

          th {
            padding: 5px;
          }

          th,
          td {
            white-space: nowrap;
            width: auto;

            &:nth-child(2) {
              text-align: unset;
            }

            &:nth-child(3) {
              text-align: unset;
            }
          }
        }
      }
    }
  }

  .ant-table-wrapper {
    .ant-table-thead>tr>th {
      background: unset;
    }

    .ant-table-tbody>tr:last-child>td {
      border-bottom: unset;
    }

    .ant-table-container table>thead>tr:first-child> :first-child,
    .ant-table-container table>thead>tr:first-child> :last-child {
      border-radius: unset;
    }

    .ant-table {
      background-color: transparent;
      box-shadow: none;

      &.ant-table-empty {
        .ant-table-tbody>tr>td {
          border-bottom: unset;
        }
      }

      table {

        th,
        td {
          padding: 15px 32px;
          font-size: 1.3rem;
        }

        th {
          white-space: nowrap;
        }

        td {
          white-space: normal;

          // span {
          a {
            color: #4183c4;
          }

          // }
        }

        thead.ant-table-thead {
          position: relative;

          tr {
            background-color: whitesmoke;
            position: sticky;
            width: 100%;
            left: 0;
            top: 0;
            z-index: 2;

            th {
              border-bottom: 1px solid $input-border;
              border-top: 1px solid $input-border;

              &::before {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

.popover {
  i {
    cursor: pointer;
  }
}

.scrollHeader {
  .ant-popover .ant-popover-inner {
    background-color: #fff;
  }
}

.ant-popover .ant-popover-inner {
  background-color: transparent;
}

.ant-popover {
  border: 1px solid #e2dcdc;

  @include theme() {
    background-color: theme-get("card");
  }

  border-radius: 8px;

  .ant-popover-inner-content {
    @include theme() {
      color: theme-get("Text-theme-achor");
    }
  }

  .ant-popover-title {
    @include theme() {
      color: theme-get("Text-theme-achor");
    }
  }

  .tooltips {
    display: flex;
    justify-content: space-between;
    gap: 50px;

    p {
      font-size: 12px;

      @include theme() {
        color: theme-get("Text-theme-achor");
      }
    }
  }

  .ant-popover-inner {
    background-color: unset;
  }
}

// ===============================ExchnageTheme=============================

.exchangeHeader {
  @include theme() {
    background-color: theme-get("card");
  }

  span {

    a,
    ant-dropdown-link {
      @include theme() {
        color: theme-get("Text-theme-achor");
      }
    }
  }
}

.exchangefooter {
  .footerlogo {
    span {
      @include theme() {
        color: theme-get("Text-theme-achor");
      }
    }
  }
}

span.theme {
  @include theme() {
    color: theme-get("Text-theme-achor");
  }
}

.exchange-page {
  .ant-select-selector {
    .ant-select-selection-item {
      @include theme() {
        color: theme-get("Text-theme-achor");
      }
    }
  }

  // min-height: calc(100vh - 160px);
  // height: 100%;
  // padding: 20px 0;
  .error_msg {
    color: red;
  }

  .wallet {
    span {
      a {
        @include theme() {
          color: theme-get("Text-theme-achor");
        }
      }
    }

    .error_msg:empty {
      display: none;
    }

    img {
      cursor: pointer;
      margin-left: 5px;
    }
  }

  .wallet,
  .wallet>a,
  h3,
  div.ant-empty-description,
  p {
    @include theme() {
      color: theme-get("Text-theme-achor");
    }

    &.positive {
      color: #0ecb81;
    }

    &.negative {
      color: #ff4d4f;
    }
  }

  .grap-top {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    >div {
      width: 100%;
    }

    h3 {
      font-size: 13px;
      font-weight: 400;
      margin-bottom: 0.5rem;
    }

    p {
      font-size: 13px;
      font-weight: 500;
    }
  }

  .midle-sec {
    @include theme() {
      background-color: theme-get("Thead-Bg");
    }

    span.themeColor {
      @include theme() {
        color: theme-get("Text-theme-achor");
      }
    }
  }

  .hdr-row {
    @include theme() {
      background-color: theme-get("Thead-Bg");
    }
  }

  .buysell-inner-tabs {
    .ant-tabs.ant-tabs-top {
      .ant-tabs-nav-list .ant-tabs-tab {
        flex-basis: auto;
      }
    }

    .ant-tabs {

      // &.ant-tabs-left {
      .ant-tabs-nav {
        // width: 100%;

        .ant-tabs-tab {
          text-align: center;

          .ant-tabs-tab-btn {
            margin: auto;
            font-size: 1.2rem;
            min-width: 5rem;
            color: $baseText;
          }

          &:hover {
            color: $baseText;
          }

          &-active {
            color: $baseText;
            text-align: center;
          }
        }
      }

      // }
    }

    * {
      .ant-tabs-ink-bar {
        background-color: $baseText;
      }
    }

    .ant-tabs .ant-tabs-tab+.ant-tabs-tab {
      margin-left: 0.5rem;
    }
  }

  .custom-table {
    .ant-table-wrapper {
      .ant-table-tbody>tr.ant-table-placeholder {
        @include theme() {
          background-color: theme-get("card");
        }
      }

      &.positive {
        color: #0ecb81;
      }

      &.negative {
        color: #ff4d4f;
      }
    }
  }

  .ant-input-disabled,
  .ant-input[disabled],
  .ant-input-affix-wrapper-disabled,
  .ant-input-affix-wrapper[disabled] {
    @include theme() {
      color: theme-get("Text-theme-achor");
    }
  }

  // * {
  // @include theme() {
  //   color: theme-get("Text-theme-achor");
  // }

  .custom-table {
    .ant-table-wrapper {
      .ant-table-tbody>tr.ant-table-placeholder {
        @include theme() {
          background-color: theme-get("card");
        }
      }

      .ant-table-cell-row-hover {
        @include theme() {
          background-color: theme-get("table-hover-exchange");
        }
      }

      .ant-table.ant-table-empty .ant-table-tbody>tr>td {
        border-bottom: unset;
      }

      .ant-table-tbody>tr>td {
        border: 0;
        padding: 2px 5px;

        span {
          border: 0;
        }

        @include theme() {
          color: 1px solid theme-get("table-border-exchnage");
        }
      }

      .ant-table {
        table {
          thead.ant-table-thead {
            tr {
              @include theme() {
                background-color: theme-get("Thead-Bg");
              }

              th {
                @include theme() {
                  color: theme-get("Text-theme-achor");
                  border-bottom: 1px solid theme-get("table-border-exchnage");
                  border-top: 1px solid theme-get("table-border-exchnage");
                }

                &::before {
                  display: none;
                }
              }
            }
          }
        }
      }
    }
  }

  .custom-table .ant-table-wrapper .ant-table table thead.ant-table-thead tr {
    @include theme() {
      background-color: theme-get("Thead-Bg");
      color: theme-get("Text-theme-achor");
    }
  }

  th,
  td {
    @include theme() {
      color: theme-get("Text-theme-achor");
    }
  }

  // }

  @include theme() {
    background-color: theme-get("ExchangeBg");
  }

  .card-outer-basic {
    @include theme() {
      background-color: theme-get("card");
    }
  }

  // .graph {
  //   height: 100%;
  // }

  // .order-book {
  //   border-radius: 8px;
  //   height: 65vh;
  //   overflow: scroll;
  // }

  .butSell-part {
    height: 100%;

    input.ant-input,
    span.ant-input-suffix {
      @include theme() {
        color: theme-get("Text-theme-achor");
      }
    }

    h3 {
      font-size: 14px;
      font-weight: 500;
    }

    .doubleFldWrap {
      position: relative;

      .error_msg {
        color: red;
        position: absolute;
        top: 50px;
        left: 0;
      }

      .lable-input {
        position: relative;

        .error_msg {
          color: red;
          position: absolute;
          top: 50px;
          left: 0;
        }
      }

      .singleFld {
        position: relative;
      }

      .ant-form-item {
        margin-bottom: 30px;
      }
    }

    .errorData {
      // margin-top: 24px;

      .errorAm {
        color: red;
      }
    }

    .ant-tabs-nav-operations {
      display: none;
    }

    .wallet {
      display: flex;
      gap: 5px;

      span {
        display: flex;
        align-items: center;
      }
    }
  }

  .trade {
    // height: 35vh;
    // overflow: hidden;

    // @media (max-width: 1599px) and (min-width: 1200px) {
    //   height: 65vh;
    // }

    // @media (max-width: 1199px) and (min-width: 991px) {
    //   height: 75vh;
    // }

    .ant-table-body {
      @media (max-width: 1599px) and (min-width: 1200px) {
        max-height: calc(510px) !important;
      }

      @media (max-width: 1199px) and (min-width: 991px) {
        max-height: calc(694px) !important;
      }
    }
  }

  .customTab_style {

    .ant-tabs-top>.ant-tabs-nav::before,
    .ant-tabs-bottom>.ant-tabs-nav::before,
    .ant-tabs-top>div>.ant-tabs-nav::before,
    .ant-tabs-bottom>div>.ant-tabs-nav::before {
      @include theme() {
        border-bottom: 1px solid theme-get("table-border-exchnage");
      }
    }
  }

  .ant-input-affix-wrapper {
    @include theme() {
      background-color: theme-get("Thead-Bg");
      border-color: theme-get("table-border-exchnage");
    }

    &:hover {
      border-color: $baseColor;
    }

    input.ant-input {
      @include theme() {
        background-color: transparent;
      }
    }
  }

  .ant-slider .ant-slider-mark-text {
    @include theme() {
      color: theme-get("Text-theme-achor");
    }
  }

  .ant-slider .ant-slider-rail {
    @include theme() {
      background-color: theme-get("Thead-Bg");
    }
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    @include theme() {
      background-color: transparent;
      // border-color: theme-get("table-border-exchnage");
      border: 0;
    }

    // .ant-select-arrow {
    //   color: grey;
    // }
  }

  .ant-input::placeholder,
  label {
    @include theme() {
      color: theme-get("Text-theme-achor");
    }
  }

  .ant-table-wrapper .ant-table-tbody>tr.ant-table-placeholder:hover td {
    background: unset;
  }

  .buy-sell-design>.buySell-tabs {
    &:first-child {
      .ant-tabs-tab.ant-tabs-tab-active {
        background-color: black;
      }
    }
  }

  .ant-tabs-top>.ant-tabs-nav::before {
    border: unset;
  }

  .ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-tab {
    border-radius: 8px;

    &:first-child {
      background-color: #0ecb81;
      border-color: #0ecb81;
    }

    &:nth-child(2) {
      background-color: #f6465d;
      border-color: #f6465d;
    }

    &:hover {
      background-color: transparent;
    }
  }

  .ant-tabs-card {
    .ant-tabs-nav-list {
      width: 100%;

      .ant-tabs-tab {
        flex-basis: 50%;
        text-align: center;
        padding: 9px 0;
        font-weight: 600;

        .ant-tabs-tab-btn {
          text-align: center;
          width: 100%;
          color: white;
        }

        &:hover {
          @include theme() {
            color: theme-get("Text-theme-achor") !important;
          }

          .ant-tabs-tab-btn {
            @include theme() {
              color: theme-get("Text-theme-achor") !important;
            }
          }
        }
      }
    }
  }

  .ant-modal-content {
    @include theme() {
      background-color: theme-get("card");
      color: theme-get("Text-theme-achor");
    }
  }
}

// ============================================responsive-header================================

.header {
  div.ant-drawer-wrapper-body {
    background-color: whitesmoke;
  }

  span.ant-menu-title-content {
    color: black;

    a {
      color: black;

      &:hover {
        color: $baseColor  !important;
      }
    }

    &:hover {
      color: $baseColor;
    }
  }

  &.landingHeader {
    div.ant-drawer-wrapper-body {
      background-color: black;
    }

    * {
      span.ant-menu-title-content {
        color: white;

        a {
          color: white;
        }

        &:hover {
          color: $baseColor;
        }
      }
    }
  }

  &.exchangeHeader {
    div.ant-drawer-wrapper-body {
      @include theme() {
        background-color: theme-get("ExchangeBg");
      }
    }

    * {
      span.ant-menu-title-content {
        @include theme() {
          color: theme-get("Text-theme-achor");
        }

        a {
          @include theme() {
            color: theme-get("Text-theme-achor");
          }
        }

        &:hover {
          color: $baseColor;
        }
      }
    }
  }
}

.responsive {
  max-width: 100%;
  height: auto;
}

.ant-collapse {
  .ant-collapse-item {
    .ant-collapse-header {
      font-weight: 600;
    }
  }
}

// ===============================ExchnageEnd=============================

body::-webkit-scrollbar {
  width: 4px !important;
  border-radius: 0px !important;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 0px !important;
}

body::-webkit-scrollbar-thumb {
  background-color: #fff !important;
  border-radius: 0px !important;
}

::-webkit-scrollbar-thumb {
  background: #fff !important;
}

.markitDetailTop {
  position: relative;
  transition: all 0.3s;

  // @include theme() {
  //   background-color: theme-get("card");
  //   // box-shadow: 1px 1px 12px 10px rgba(0, 0, 0, 0.02);
  //   // border-radius: 8px;
  // }

  .ant-tabs-tab {
    @include theme() {
      background-color: theme-get("tabbg");
    }

    border-radius: 9px;
    padding: 4px;
    height: 35px;
  }

  .ant-tabs-left {
    .ant-tabs-content-left {
      display: none;
    }
  }

  .ant-tabs-tab-active {
    background-color: #fcd535 !important;
    color: #fff;

    i {
      color: #fff;
    }

    .ant-tabs-tab-btn {
      color: #fff !important;
    }
  }

  .ant-tabs-tab-btn {
    color: grey !important;
  }

  .ant-tabs-top {
    .ant-tabs-content-top {
      height: 50vh;
    }
  }

  // height: 60vh;
  // background-color: black;

  // @media (min-width: 1366px) {
  &.marketDrawer {
    -webkit-box-shadow: 1px 1px 10px 0px rgba(177, 177, 177, 0.4);
    -moz-box-shadow: 1px 1px 10px 0px rgba(177, 177, 177, 0.4);
    box-shadow: 1px 1px 10px 0px rgba(177, 177, 177, 0.4);
    // width: 100%;
    // height: 50vh;

    // max-width: 320px;
    position: absolute;
    z-index: 3;
    height: 540px;
    transition: all 0.1s;

    .content {
      display: block;
      max-width: 320px;
      min-width: 320px;
      transition: all 0.3s;
    }

    &.closeMarketDrawer {
      .ant-tabs-nav {
        width: 100% !important;
      }

      .market-tabs {
        .ant-tabs {
          .ant-tabs-nav {
            width: 100% !important;

            .ant-tabs-tab-btn {
              margin: auto;
            }
          }
        }
      }

      @media (min-width: 1366px) {
        .ant-tabs-tab .ant-tabs-tab-active {
          margin-right: 0 !important;
          width: 60px;
          display: flex;
          justify-content: center;
        }
      }
    }
  }

  .rgt-top-sec .radio-srch-sec .radio-btns {
    display: flex;
    flex-wrap: nowrap;
  }

  .ant-tabs-content-holder {
    border-left: 0;
  }

  .market-tabs {
    .ant-radio-inner {
      height: 12px;
      width: 12px;
    }

    .ant-input-affix-wrapper-focused {
      .ant-input-prefix {
        svg {
          @include theme() {
            fill: theme-get("themeprimary") !important;
          }
        }
      }
    }

    .ant-input-affix-wrapper {
      padding: 4px !important;
      max-width: 108px;
      border: 0;

      svg {
        margin-left: 8px;

        @include theme() {
          fill: theme-get("themeprimary") !important;
        }
      }

      input {
        @include theme() {
          color: theme-get("themeprimary");
        }
      }
    }
  }
}

.market-tabs {
  @media (max-width: 991px) {
    .ant-tabs-tab-active {
      background-color: #fcd535 !important;

      >div {
        color: #fff !important;
      }

      i {
        color: #fff !important;
      }
    }

    .ant-tabs-tab {
      border-radius: 9px;
      padding: 4px;
      height: 35px;
    }

    .ant-input-affix-wrapper input {
      color: #f1f1f1;
    }

    .ant-input-affix-wrapper {
      padding: 4px 10px !important;
      max-width: 100%;
      border: 0;
      margin-bottom: 10px;
    }
  }

  .ant-table-wrapper {
    table {
      td {
        a {
          @include theme() {
            color: theme-get("Text-theme-achor") !important;
          }
        }

        .ant-tag.red {
          color: red !important;
          border: 0;
        }

        .ant-tag.green {
          color: green;
        }
      }
    }
  }

  .ant-tabs {
    .ant-tabs-tab-btn {
      margin: auto;
      font-size: 1.2rem;
      min-width: 5rem;
      // color: $baseText;
    }

    .ant-tabs-tab {
      text-align: center;

      .ant-tabs-tab-btn {
        margin: auto;
        font-size: 1.2rem;
        min-width: 5rem;
        color: $baseText;
      }

      &:hover {
        color: $baseText;
      }

      &-active {
        color: $baseText;
        text-align: center;
      }
    }
  }

  * {
    .ant-tabs-ink-bar {
      background-color: transparent;
    }
  }

  .ant-tabs-nav-list {
    gap: 5px !important;
  }

  .ant-tabs .ant-tabs-tab {
    margin-left: 0.5rem;
    margin-top: 0;
  }
}

.loader-container {
  // width: 100%;
  // height: 100vh;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // position: fixed;
  // background: rgba(0, 0, 0, 0.834);
  // z-index: 10000;

  .loader {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    background: rgba(0, 0, 0, 0.834);
    z-index: 10000;

    .lottieAntimationdata {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 100%;
      z-index: 999;
      position: relative;

      svg {
        max-width: 100px;
      }
    }
  }
}

html,
body {
  height: 100%;
}

.errorRed {
  color: #ff4d4f;
}

.negative {
  color: #be2e1f;
}

.positive,
p.positive {
  color: #0ecb81;
}

.progress-bar.ask-bar {
  background-color: #f84960;
}

.progress-bar.bid-bar {
  background-color: #3cb569;
}

.ant-table-cell.emptyTh {
  display: none;
}

.status-rejected,
.status-skipped,
.status-failed,
.status-canceled,
.status-errored {
  color: red;
}

.status-confirming,
.status-completed,
.status-succeed,
.status-complete,
.status-collecting,
.status-collected {
  color: #68bc93;
}

.status-pending,
.status-processing,
.status-prepared {
  color: rgb(252, 213, 53);
}

// --------------------------------------text-Content-------------------
.ant-menu-light.ant-menu-horizontal>.ant-menu-item-selected::after,
:where(.css-dev-only-do-not-override-3mqfnx).ant-menu-light>.ant-menu.ant-menu-horizontal>.ant-menu-item-selected::after,
:where(.css-dev-only-do-not-override-3mqfnx).ant-menu-light.ant-menu-horizontal>.ant-menu-submenu-selected::after,
:where(.css-dev-only-do-not-override-3mqfnx).ant-menu-light>.ant-menu.ant-menu-horizontal>.ant-menu-submenu-selected::after {
  border-bottom: none;
}

.Acceptence,
.use-globel,
.policy-text {
  p {
    margin-bottom: 15px;
  }

  .headingTexts,
  .headprivacy {
    padding-bottom: 12px;
    padding-top: 15px;
    font-size: 16px;
    color: #1f1f2c;
    font-weight: 500;
  }
}

.legal-page,
.amlpolicy {
  .docHis {
    padding-bottom: 12px;
    padding-top: 15px;
    font-size: 16px;
    color: #1f1f2c;
    font-weight: 500;
  }

  .ant-menu-title-content {
    color: #a5a5ab;
    font-weight: 500;

    &:hover {
      color: #000 !important;
    }
  }

  .ant-menu-light {
    background-color: transparent;

    .ant-menu-item {
      color: black;
      font-weight: 600;
      font-size: 14px;
    }

    .ant-menu-item-selected,
    .ant-menu-light>.ant-menu .ant-menu-item-selected {
      background-color: #fcd535;
      color: black !important;
      font-weight: 600;
      font-size: 14px;

      span {
        color: #000;
      }
    }
  }

  .ant-menu-inline>.ant-menu-item,
  .ant-menu-vertical>.ant-menu-item,
  .ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title,
  .ant-menu-vertical>.ant-menu-submenu>.ant-menu-submenu-title {
    height: 35px;
    line-height: 35px;

    @media (max-width: 575px) {
      line-height: 28px;
      height: 28px;
    }
  }

  .ant-menu-light.ant-menu-root.ant-menu-inline,
  .ant-menu-light>.ant-menu.ant-menu-root.ant-menu-inline,
  .ant-menu-light.ant-menu-root.ant-menu-vertical,
  .ant-menu-light>.ant-menu.ant-menu-root.ant-menu-vertical {
    border-inline-end: unset;
  }

  .ant-menu-light:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):hover,
  .ant-menu-light>.ant-menu:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):hover {
    background-color: #f5f5f5;
  }
}

.ant-menu-item {
  &-active {
    border-bottom: unset;
    background-color: unset;
  }
}

.cookies-table {

  table,
  th,
  td {
    border: 1px solid black;
    border-collapse: collapse;
    padding: 10px;
  }

  .textgreen {
    color: #0ecb81;
  }
}

.settingList {
  button.ant-btn.ant-btn-default:not(:disabled):not(.ant-btn-disabled) {
    &.active {
      background-color: #0ecb81;
      color: #fff;
      border-color: #0ecb81;

      &:hover {
        background-color: #0ecb81;
        border-color: #0ecb81;
        color: #fff;
        opacity: 0.8;
      }
    }
  }
}

.ant-drawer {
  .ant-menu-title-content {
    [aria-label="caret-down"] {
      display: none;
    }
  }

  .ant-menu-submenu-inline {
    .ant-menu-title-content {
      display: flex;
    }
  }
}

.pagesContent {
  color: #4d4848;
  // margin: 40px auto;

  // @media(max-width:575px) {
  //   margin: 20px auto;
  // }

  * {
    line-height: 24px;

    a {
      color: #2d84f5 !important;
    }
  }

  ul {
    margin: 10px 0;
    padding-left: 15px;

    @media (max-width: 575px) {
      padding-left: 0;
    }

    li {
      list-style-type: disc;
      padding-left: 20px;
      margin-bottom: 8px;
    }
  }

  h2.main-text {
    @media (max-width: 575px) {
      font-size: medium;
    }
  }
}

.cookiePolicy {
  ol {
    list-style-type: upper-roman;
  }

  .headingTexts {
    padding-bottom: 12px;
    padding-top: 15px;
    font-size: 16px;
    color: #1f1f2c;
    font-weight: 500;
  }
}

.digitalAsset {
  counter-reset: section;

  .headingTexts {
    padding-bottom: 12px;
    padding-top: 15px;
    font-size: 16px;
    color: #1f1f2c;
    font-weight: 500;
  }

  h5,
  h6 {
    padding-bottom: 12px;
    padding-top: 15px;
    font-size: 16px;
    color: #1f1f2c;
    font-weight: 500;
    /* Set "subsection" to 0 */
    counter-reset: subsection;
  }

  h5::before {
    counter-increment: section;
    content: counter(section) ". ";
  }

  h6::before {
    counter-increment: subsection;
    content: counter(section) "." counter(subsection) " ";
  }
}

.earnTerms {
  ol {
    list-style-type: lower-alpha;
  }
}

textarea.ant-input {
  border-color: #eaecef;
  resize: none;
  overflow: scroll;

  &:hover,
  &:focus-within {
    border-color: $border-hover;
    box-shadow: none;
  }
}

.paginationStyle {
  .ant-pagination {
    padding: 20px;
    text-align: center;

    .ant-pagination-item-active {
      border-color: $baseText;

      a {
        color: $baseText;
      }
    }
  }
}

.Header-menu {
  span.ant-menu-title-content {
    color: white;

    &:hover {
      color: $baseText;
    }
  }
}

.progress-container {
  position: relative;

  .row-content {
    border-bottom: 0 !important;
    margin-bottom: 2px;

    span {
      padding: 1px 5px;
    }
  }
}

.row-content {
  z-index: 2;
  position: relative;
}

.progress-bar.ask-bar {
  background-color: #f84960;
}

.progress-bar {
  position: absolute;
  top: 0px;
  right: 0;
  opacity: 0.15;
  height: 100%;
  width: 100%;
  left: 100%;
  z-index: 1;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
}

.exchange-page {
  padding: 20px 10px;

  * {
    h3 {
      font-size: 1.4rem;
      text-align: left;
    }
  }
}

.grid-container {
  display: grid;
  grid-template-columns: 5% auto 20% 22%;
  gap: 10px;

  @media (max-width: 1599px) {
    grid-template-columns: 8% auto 32%;
  }

  @media (max-width: 1199px) {
    grid-template-columns: 8% 24% auto 32%;
  }

  @media (max-width: 991px) {
    grid-template-columns: 100%;
  }
}

.grid-container>div {
  @include theme() {
    background-color: theme-get("card");
    box-shadow: 1px 1px 12px 10px rgba(0, 0, 0, 0.02);
    border-radius: 8px;
  }
}

// prices

.item1 {
  grid-column-start: 1;
  grid-column-end: 3;

  @media (max-width: 1199px) {
    grid-column-start: 1;
    grid-column-end: 5;
    // grid-row-start: 1;
  }

  @media (max-width: 991px) {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 3;
  }
}

// oderbook
.item2 {
  grid-column-start: 3;
  grid-column-end: 4;
  grid-row-start: 1;
  grid-row-end: 3;

  @media (max-width: 1199px) {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 3;
    grid-row-end: 4;
  }

  @media (max-width: 1199px) {
    grid-row-start: 3;
    grid-row-end: 4;
  }

  @media (max-width: 991px) {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 5;
    grid-row-end: 6;
  }
}

// buySell
.item3 {
  grid-column-start: 4;
  grid-column-end: 5;
  grid-row-start: 1;
  grid-row-end: 4;

  @media (max-width: 1599px) {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 3;
    grid-row-end: 4;
  }

  @media (max-width: 1199px) {
    grid-column-start: 3;
    grid-column-end: 4;
    grid-row-start: 3;
    grid-row-end: 4;
  }

  @media (max-width: 991px) {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 6;
    grid-row-end: 7;
  }
}

// maket
.item4 {
  @include theme() {
    background-color: theme-get("card");
  }

  grid-column-start: 1;
  grid-column-end: 2;
  border-radius: 9px;
  overflow: hidden;
  height: 100%;

  @media (max-width: 991px) {
    // grid-column-start: 1;
    // grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 2;

    @include theme() {
      background-color: theme-get("card") !important;
    }
  }
}

// Graph
.item5 {
  grid-column-start: 2;
  grid-column-end: 3;

  @media (max-width: 1199px) {
    grid-column-start: 2;
    grid-column-end: 5;
    grid-row-start: 2;
    grid-row-end: 3;
  }

  @media (max-width: 991px) {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 3;
    grid-row-end: 4;
  }
}

// order
.item6 {
  grid-column-start: 1;
  grid-column-end: 3;

  @media (max-width: 1599px) {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 4;
    grid-row-end: 5;
  }

  @media (max-width: 1199px) {
    grid-column-start: 1;
    grid-column-end: 5;
    grid-row-start: 6;
    grid-row-end: 7;
  }

  @media (max-width: 991px) {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 8;
    grid-row-end: 9;
  }

  // .ant-tabs-tab .ant-tabs-tab-active {
  //   color: #0ecb81;
  // }

  // .customTab_style .ant-tabs .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-btn {
  //   color: ;
  // }
}

// trade

.item7 {
  @media (max-width: 991px) {
    // grid-column-start: 1;
    // grid-column-end: 2;
    grid-row-start: 7;
    grid-row-end: 8;
  }
}

th.ant-table-cell-fix-right {
  background-color: red;
}

.ant-table-layout-fixed table {
  table-layout: auto;
}

.ant-menu-light.ant-menu-horizontal>.ant-menu-item:hover::after,
.ant-menu-light>.ant-menu.ant-menu-horizontal>.ant-menu-item:hover::after,
.ant-menu-light.ant-menu-horizontal>.ant-menu-submenu:hover::after,
.ant-menu-light>.ant-menu.ant-menu-horizontal>.ant-menu-submenu:hover::after,
.ant-menu-light.ant-menu-horizontal>.ant-menu-item-active::after,
.ant-menu-light>.ant-menu.ant-menu-horizontal>.ant-menu-item-active::after,
.ant-menu-light.ant-menu-horizontal>.ant-menu-submenu-active::after,
.ant-menu-light>.ant-menu.ant-menu-horizontal>.ant-menu-submenu-active::after,
.ant-menu-light.ant-menu-horizontal>.ant-menu-item-open::after,
.ant-menu-light>.ant-menu.ant-menu-horizontal>.ant-menu-item-open::after,
.ant-menu-light.ant-menu-horizontal>.ant-menu-submenu-open::after,
.ant-menu-light>.ant-menu.ant-menu-horizontal>.ant-menu-submenu-open::after {
  border-bottom-width: 0;
  border-bottom-color: none;
}

.ant-menu-light.ant-menu-horizontal>.ant-menu-item::after,
.ant-menu-light>.ant-menu.ant-menu-horizontal>.ant-menu-item::after,
.ant-menu-light.ant-menu-horizontal>.ant-menu-submenu::after,
.ant-menu-light>.ant-menu.ant-menu-horizontal>.ant-menu-submenu::after {
  border-bottom: none;
}

.a-link {
  color: $baseText;

  &:hover {
    color: black;
    text-decoration: underline;
  }
}

.both-extchange {
  .bid-body {
    overflow-y: hidden !important;
  }
}

.currency_latest_price {
  span {
    font-size: 12px;
  }

  .stable {
    @include theme() {
      color: theme-get("Text-theme-achor") !important;
    }
  }
}

.markitDetailTop {
  @media only screen and (max-width: 1599px) and (min-width: 1445px) {
    top: 208px;
    width: 120px;
  }

  @media only screen and (max-width: 1599px) and (min-width: 1366px) {
    top: 230px;
  }

  @media (min-width: 991px) {
    min-height: 505px;
  }

  @media (min-width: 1400px) {
    width: 113px;
  }

  @media (min-width: 1366px) {
    position: absolute;
    z-index: 1;
    top: 235px;
    // width: 96px;
    transition: 0.1s linear;
    min-height: 540px;
    margin-right: 5px;
    border-radius: 5px !important;

    &.marketDrawer {
      -webkit-box-shadow: 1px 1px 10px 0px rgba(177, 177, 177, 0.4);
      -moz-box-shadow: 1px 1px 10px 0px rgba(177, 177, 177, 0.4);
      box-shadow: 1px 1px 10px 0px rgba(177, 177, 177, 0.4);
      // width: 100%;
      width: 320px;

      @include theme() {
        background-color: theme-get("card");
      }
    }

    @media (max-width: 1675px) and (min-width: 1600px) {
      h3 {
        padding: 10px 0px !important;
      }
    }
  }

  @media (min-width: 1420px) {
    top: 236px;
  }

  @media (min-width: 1679px) {
    top: 242px;
    width: 88px;
  }

  @media (min-width: 1890px) {
    width: 95px;
  }

  @media (max-width: 1675px) and (min-width: 1600px) {
    top: 238px;
    width: 78px;
    padding: 2px;
  }

  &.marketDrawer {
    -webkit-box-shadow: 1px 1px 10px 0px rgba(177, 177, 177, 0.4);
    -moz-box-shadow: 1px 1px 10px 0px rgba(177, 177, 177, 0.4);
    box-shadow: 1px 1px 10px 0px rgba(177, 177, 177, 0.4);
    // width: 100%;
    width: 320px;

    @include theme() {
      background-color: theme-get("card");
    }

    .ant-table-body {
      overflow-x: hidden;
      scrollbar-width: none;

      ::-webkit-scrollbar {
        display: none;
      }
    }

    .ant-tabs-nav-wrap {

      .ant-tabs-nav-list {
        overflow-x: auto;


        ::-webkit-scrollbar {
          width: 1px !important;
        }

        .ant-tabs-tab {
          margin-bottom: 5px;
        }
      }

    }

    .ant-tabs-nav-operations {
      display: none !important;
    }
  }

  .rgt-top-sec .radio-srch-sec .radio-btns {
    display: flex;
    flex-wrap: nowrap;
  }
}

.graphHeading {
  h3 {
    font-size: 14px;
    font-weight: 500;
  }
}

.ant-menu-light .ant-menu-item-selected,
.ant-menu-light>.ant-menu .ant-menu-item-selected {
  background-color: transparent;
}

.ant-menu-light.ant-menu-root.ant-menu-inline,
.ant-menu-light>.ant-menu.ant-menu-root.ant-menu-inline,
.ant-menu-light.ant-menu-root.ant-menu-vertical,
.ant-menu-light>.ant-menu.ant-menu-root.ant-menu-vertical {
  border-inline-end: none;
}

.ant-menu-light:not(.ant-menu-horizontal) .ant-menu-submenu-title:active,
.ant-menu-light>.ant-menu:not(.ant-menu-horizontal) .ant-menu-submenu-title:active {
  background-color: transparent;
}

.exchangeHeader {

  .ant-menu .ant-menu-submenu-arrow::before,
  .ant-menu .ant-menu-submenu-arrow::after {
    @include theme() {
      background-color: theme-get("Text-theme-achor");
    }
  }
}

.pdf-page {
  margin-left: auto;

  // @media(max-width:575px) {
  //   flex-direction: column;
  //   text-align: center;
  // }

  a {

    &:hover,
    &:focus {
      color: $baseText;
    }
  }

  @media (max-width: 480px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
  }
}

.closeMarketDrawer {
  .ant-tabs-left>.ant-tabs-nav .ant-tabs-tab+.ant-tabs-tab {
    margin: 5px 0 0 0;
  }

  .ant-tabs {
    width: 100%;
  }

  .ant-tabs-nav {
    max-width: 150px !important;
    width: 100%;

    .ant-tabs-tab {
      width: 100%;
      margin-left: 0;
      display: flex;
      justify-content: center;
    }
  }
}

.light {
  .exchange-page .ant-tabs-card .ant-tabs-nav-list .ant-tabs-tab .ant-tabs-tab-btn {
    &:hover {
      color: #000;
    }
  }
}

.ant-menu-overflow-item,
.ant-menu-overflow-item-rest,
.ant-menu-submenu-horizontal {
  white-space: nowrap !important;
  overflow: visible !important;
  text-overflow: initial !important;
}

.commonpadding {
  padding: 30px 27px;

  @media (max-width: 575px) {
    padding: 10px;
  }
}

.modalHeader {
  h4 {
    @include fluid-type(320px, 767px, 18px, 23px);
  }

  text-align: center;

  h3 {
    width: 30%;
    color: rgb(29, 28, 28);
  }

  p {
    width: 80%;
    white-space: nowrap;
    color: rgb(29, 28, 28);
  }
}

.modaldata {
  p {
    display: flex;
    gap: 5px;

    @media (max-width: 575px) {
      font-size: 12px;
    }
  }
}

.spanBalance {
  display: flex;
  justify-content: center;
  gap: 5px;
  margin-bottom: 10px;
}

// .exchange-advance::-webkit-scrollbar {
//   display: none !important;
// }

.orderReverse {
  scrollbar-width: none;
}

body {
  ::-webkit-scrollbar {
    width: 4px;
    border-radius: 0px;

    &-thumb {
      background-color: #888;
      border-radius: 6px;
    }

    &-thumb:hover {
      background-color: #555;
    }
  }
}

.ant-table-cell.ant-table-cell-scrollbar {
  display: none;
}

.ant-tabs-tabpane.ant-tabs-tabpane-active {
  .both-extchange {
    .ask-body.reverse-row.orderReverse.table-type-soket {
      height: 277px !important;
    }

    .bid-body.inner {
      overflow-y: scroll;
      padding-bottom: 0;
      margin-top: 0;
      position: absolute;
      bottom: 44px;
      width: 100%;
      overflow-x: hidden;
    }
  }

  .ask-body.reverse-row.orderReverse.table-type-soket {
    height: 549px !important;
    overflow-y: scroll !important;
    overflow-x: hidden;
    // .bid-body.inner {
    //   bottom: 0px !important;
    // }

    .askorder-tbl.reverse-flow.center-content-issue {
      #ask-orders {
        .bid-body.inner {
          position: static !important;
        }
      }
    }
  }

  .bidorder-tbl.bid_order_OverflowHdn.bidorder-tbls {
    .bid-body {
      height: 544px !important;
      scrollbar-width: none;
      overflow-x: hidden !important;

      ::-webkit-scrollbar {
        scrollbar-width: none;
      }
    }
  }
}

.commonSkel {
  margin: 10px 0 10px 0;
}

.country_number {
  display: flex;
  gap: 10px;

  @media (max-width: 420px) {
    flex-direction: column;
    gap: 0;
  }

  .phone_number {
    width: 70%;

    @media (max-width: 420px) {
      width: 100%;
    }
  }

  .country_code {
    width: 30%;

    @media (max-width: 420px) {
      width: 100%;
    }
  }
}

.last_tb_order {
  height: 582px;
  scrollbar-width: none !important;
}

.center-content-issue {
  height: 573px;
}

.currency_latest_price_sec {
  position: absolute;
  right: 10px;
  top: -13px;

  @media (max-width: 991px) {
    top: -18px;
    font-size: 13px;
  }
}

.modalDesc {
  .form-button {
    button {
      margin-top: 20px;
    }
  }
}

.modalCon {
  .spacing_h4 {
    @media (max-width: 580px) {
      margin-top: 20px;
    }
  }
}

.depos_withd {
  .ant-tabs-nav-list {
    padding-bottom: 0;
  }

  .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    width: 50% !important;
  }
}

.history.card-outer-basic.customTab_style.moreTabs.exchangeTabs {
  .ant-tabs-nav-list {
    @media (max-width: 545px) {
      margin-bottom: 45px;
    }
  }

  .subHeaderStyle {
    button {
      @media (max-width: 545px) {
        left: 10px;
      }
    }
  }

  .ant-tabs-nav-list {
    overflow-x: scroll;

    @media (max-width: 446px) {
      width: 100%;
      max-width: 446px;
    }
  }

  .ant-tabs-nav-operations {
    display: none;
  }
}

.sellbtnGreen {
  button {
    background-color: #f6465d;
    border-color: #f6465d;
    color: #fff;

    &:hover {
      border-color: #f6465d !important;
      color: #f6465d !important;
    }

    span {
      font-weight: 600;
    }
  }
}

.buybtnGreen {
  button {
    background-color: #0ecb81;
    border-color: #0ecb81;
    color: #fff;
    font-weight: 500;

    &:hover {
      border-color: #0ecb81 !important;
      color: #0ecb81 !important;
    }

    span {
      font-weight: 600;
    }
  }
}

.group_items {
  @media (max-width: 991px) {
    display: flex;
    justify-content: flex-end;
  }
}

.amountWrapper {
  span {
    display: block;
  }
}

.erorCls {
  color: red;
}

.ant-drawer.ant-drawer-left {
  .ant-drawer-content-wrapper {
    width: 100%;

    @media (max-width: 991px) {
      width: 280px !important;
    }

    @media (max-width: 480px) {
      width: 100% !important;
    }

    .ant-drawer-body {
      padding-top: 0px;
    }
  }

  ul {
    li {
      @media (max-width: 991px) {
        padding-left: 5px !important;

        .ant-menu-submenu-title {
          padding-left: 5px !important;
        }
      }
    }
  }
}

.drawerheader_logo {
  display: flex;
  justify-content: space-between;

  .closedrawer {
    display: none;

    @media (max-width: 480px) {
      display: block;
      margin-top: 5px;

      img {
        height: 20px;
      }
    }
  }
}

.alignModalItems {
  h4 {
    text-align: center;
    font-size: 20px;
  }

  button {
    text-align: center;
    display: block !important;
    margin: auto !important;

    @media (max-width: 767px) {
      margin-top: 20px !important;
    }
  }

  .code2faSec {
    h5 {
      font-size: 13px !important;
      color: grey !important;
      font-weight: 500;
    }
  }
}

.btnCancel {
  background-color: #f6465d;
  padding: 3px 7px;
  border-radius: 3px;
  color: #fff !important;
}

.side-btn.sell {
  background-color: #f6465d;
  padding: 3px 7px;
  border-radius: 3px;
  margin-top: 4px;
}

.side-btn.buy {
  background-color: rgb(112, 168, 0);
  padding: 3px 6px;
  border-radius: 3px;
  margin-top: 4px;
  color: #fff;
}

.mrright {
  margin-right: 4px;
}

.custom-table.exchange-table.history-table {
  table {
    .ant-table-thead {
      th {
        &:last-child {
          text-align: center;
        }
      }
    }

    .ant-table-tbody {
      td {
        padding: 2px 0px;

        &:first-child {
          padding: 10px 5px 1px;
        }

        &:last-child {
          text-align: center;
        }
      }
    }
  }
}

.hoverable_btn {
  &:hover {
    @include theme() {
      color: theme-get("Text-theme-achor") !important;
    }
  }
}

.paginationStyle {
  .ant-pagination-options {
    width: 90px;

    .ant-select.ant-pagination-options-size-changer {
      width: 90px;
    }
  }
}

.exchange-advance.exchange-page,
.depos_withd {
  .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    display: none;
  }

  .ant-tabs-tab.ant-tabs-tab-active {
    border-bottom: 1px solid #fcd535;
  }
}

#sumsub-websdk-container {
  min-height: 81vh !important;
}

.custom-datepicker-ant .react-datepicker-ignore-onclickoutside:focus,
.custom-datepicker-ant .react-datepicker-ignore-onclickoutside {
  &:focus-visible {
    box-shadow: none !important;
    outline: none !important;
  }
}

.settingPage {
  .react-datepicker__calendar-icon {
    width: 12px !important;
  }
}

.trade.card-outer-basic {
  .ant-tabs-nav-list {
    overflow-x: auto;
  }

  .ant-tabs-nav-operations {
    display: none;
  }
}



.ant-tabs-nav-list::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.markitDetailTop {
  .exchange-table {
    overflow-x: hidden !important;
  }
}

.marketDrawer {
  .market-tabs {
    .custom-table.exchange-table {
      height: 360px !important;
      overflow: hidden;

      .ant-table-body {
        max-height: 360px !important;
        // max-height: 280px;
        overflow-y: scroll;
        overflow-x: hidden;
      }
    }
  }
}