@import "../../assets/theme/_var.scss";
@import "../../assets/theme/_mixins.scss";

%TextNormal16 {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 3.3rem;
  color: #1f1f2c;
}

%TextMedium18 {
  font-weight: 600;
  font-size: 1.8rem;
  line-height: 3.3rem;
  color: #1f1f2c;
}

.EmperiorCoin-landing {
  background-color: white;

  .euroMultiCurrency {
    background-image: url("../../assets/images/landingBg.svg");
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-color: #17121d;

    h2 {
      font-weight: 600;
      font-size: 54rem;
    }

    &__Everything {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      @media (max-width: 1399px) {
        padding: 100px 30px 30px 30px;
      }

      @media (max-width: 991px) {
        flex-direction: column;
        padding: 45px 15px 0px 15px;
      }

      &__left {
        .buysellContent {
          margin-bottom: 50px;

          @media (max-width: 991px) {
            margin-bottom: 10px;
          }
        }

        h2 {
          @include fluid-type(320px, 1920px, 30px, 50px);
          color: #ffffff;
          margin-bottom: 16px;
          opacity: 1;

          @media (max-width: 1199px) {
            margin-bottom: 10px;
          }

          @media (max-width: 991px) {
            max-width: 100%;
          }
        }

        p {
          color: #ffffff;
          opacity: 1;
          @include fluid-type(320px, 1920px, 16px, 20px);

          @media (max-width: 991px) {
            max-width: 100%;
          }

          @media (max-width: 767px) {}
        }

        a {
          max-width: 190px;
          height: 50px;
          padding: 18px 54.2px;
          background: #fcd535;
          border-radius: 30px;
          opacity: 1;
          color: #000;
          font-weight: 600;
          margin-top: 30px;

          @media (max-width: 1199px) {
            padding: 13px 50px;
          }
        }
      }

      &__right {
        // width: 55%;
        // position: relative;
        padding: 80px 0 50px;
        text-align: center;

        @media (max-width: 1366px) {
          padding: 0;
        }

        @media (max-width: 991px) {
          padding: 50px 0 50px;
          padding-top: 0;
          position: relative;
        }

        &::after {
          content: "";
          background: url("../../assets/images/BannerBgBlue.png");
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          position: absolute;
          top: 0;
          right: 0;
          z-index: 0;
          max-width: 800px;
          width: 100%;
          height: 100%;

          @media (max-width: 1366px) {
            right: 0;
            background-size: contain;
          }

          @media (max-width: 1199px) {
            right: -80px;
          }

          @media (max-width: 991px) {
            right: 30px;
          }

          @media (max-width: 575px) {
            right: 0px;
          }
        }

        @media (max-width: 991px) {
          width: 100%;
          display: flex;
          justify-content: center;
        }

        .fundADDImg {
          width: 100%;
          max-width: 650px;
          position: relative;
          z-index: 1;

          @media (max-width: 991px) {
            max-width: 500px;
          }

          @media (max-width: 1199px) {
            padding-top: 30px;
          }
        }
      }
    }
  }

  .cryptoChain {
    background: #fcd535 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 22px #00000029;
    border-radius: 20px;
    padding: 50px 30px;
    margin-top: -132px;
    position: relative;

    &__innerCryptoChain {
      text-align: center;

      p {
        @extend %TextNormal16;
      }

      h3 {
        @extend %TextMedium18;
      }
    }
  }

  .about {
    p {
      @extend %TextNormal16;
    }
  }

  .servicesProvide {
    background-color: #f5f5f5;

    &__globalSecInner {
      padding: 50px 23px;
      text-align: center;
      transition: 0.5s ease-in-out;

      &:hover {
        box-shadow: 0px 0px 20px #0000001a;
        border-radius: 20px;
        opacity: 1;
      }

      .imgDiv {
        background: white;
        padding: 23px;
        border-radius: 100%;
        max-width: 110px;
        margin: 0 auto;
      }

      h4 {
        color: #fcd535;
        opacity: 1;
        @include fluid-type(320px, 1920px, 16px, 20px);
        margin: 20px 0;
      }

      p {
        @extend %TextNormal16;
      }
    }
  }

  .updated {
    p {
      @extend %TextNormal16;
    }

    .playButtonsOuter {
      display: inline-flex;
      align-items: center;
      gap: 20px;
      margin-top: 50px;
    }
  }
}

.commmonHeading {
  h2 {
    color: #fcd535;
    text-transform: uppercase;
    opacity: 0.1;
    font-size: 9rem;
    word-break: break-all;

    @media (max-width: 991px) {
      font-size: 6rem;
    }

    // @include fluid-type(320px, 1920px, 50px, 100px);
    margin: 0;
  }

  h3 {
    color: #fcd535;
    opacity: 1;
    font-size: 4.4rem;

    // @include fluid-type(320px, 1920px, 22px, 44px);
    margin: 0;
    margin-top: -80px;

    @media (max-width: 479px) {
      font-size: 3.4rem;
    }
  }

  p {
    color: #000000;
    font-size: 1.6rem;
    // @include fluid-type(320px, 1920px, 14px, 16px);
    padding-top: 20px;
    margin: 0;
  }
}

.yellow-block {
  margin: 0 20px;
}

.buttons {
  display: flex;
  gap: 20px;
  margin: 30px 0 20px 0;

  @media (max-width:575px) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

  }
}

// .scrollHeader {
//   .euroCurrency {
//     .headerMainEx {
//       background-color: #17121d;
//     }
//   }
// }

// .landing {
//   .euroCurrency {
//     .headerMainEx {
//       background-color: #17121d;
//     }
//   }
// }

.p-60 {
  padding: 60px 0;

  @media (max-width: 767px) {
    padding: 20px 0 40px 0;
  }
}